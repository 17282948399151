:root {
    --accent-color: #217cb5;
    --body-color: #484848;
    --zparknotes-blue: #007aca;
}

body {
    font-family: 'Raleway', sans-serif;
    color: var(--body-color);
    margin-top: 5%;
    margin-left: 30vw;
    margin-right: 30vw;
    
}

.asterisk {
    color: var(--zparknotes-blue);
    font-size: xx-large;
    vertical-align: top;
}

.title > :not(b)  {
    color: var(--accent-color);
    font-weight: lighter;
}

.center {
    text-align: center;
}

h1, h2, h3 {
    font-weight: normal;
}